import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'realwear-cloud-marketplace-button',
  templateUrl: './marketplace-button.component.html',
  styleUrls: ['./marketplace-button.component.scss'],
  providers: [MatRipple]
})
export class MarketplaceButtonComponent implements OnInit, OnDestroy {
  private _disabled = false;

  @Input() value = '';
  @Input() icon?: IconProp;
  @Input() set beat(value: boolean) {
    if (!value) this.faClasses = [];
    this.faClasses = ['fa-shake'];
  }
  faClasses: string[] = [];

  @Input() actionStyle: 'primary' | 'secondary' | 'warning' = 'secondary';

  @Input() set disabled(value: boolean) {
    this._disabled = value;

    this.matRipple.disabled = value;
  }

  get disabled() {
    return this._disabled;
  }

  @HostBinding('class.primary') private get isPurple() {
    return this.actionStyle === 'primary';
  }

  @HostBinding('attr.disabled') @HostBinding('class.disabled') private get innerDisabled() {
    return this._disabled;
  }

  @HostBinding('class.textonly') private get isTestOnly() {
    return !this?.icon?.toString()?.length;
  }

  @HostBinding('class.warning') private get isRed() {
    return this.actionStyle === 'warning';
  }

  constructor(private matRipple: MatRipple) {}

  ngOnInit(): void {
    this.matRipple.ngOnInit();
  }

  ngOnDestroy(): void {
    this.matRipple.ngOnDestroy();
  }
}
